<template>
  <div class="container__form">
    <b-container fluid class="bg-white">
      <h2>{{ $t('form.Title') }}</h2>
      <b-row>
        <b-col class="country-group" cols="3">
          <b class="text-left">{{ $t('welcome.country') }}</b>
          <b-overlay :show="show" rounded="sm">
            <b-row class="flex-nowrap">
              <button class="card-country" :class="{ active: isActive }" @click="countrySelected('MX')">
                <img src="../../assets/images/mexico-flag.svg" />
                <p>Mexico</p>
              </button>
              <button class="card-country" :class="{ active: !isActive }" @click.prevent="countrySelected('US')">
                <img src="../../assets/images/eeuu-flag.svg" />
                <p>{{ $t('welcome.usa') }}</p>
              </button>
            </b-row>
          </b-overlay>
        </b-col>
        <b-col class="country-group text-left" cols="4">
          <b class="text-left">{{ $t('form.Program') }}</b>
          <b-overlay :show="show" rounded="sm">
            <b-form-group label="" v-slot="{ ariaDescribedby }" class="card-program">
              <b-form-radio-group
                v-model="program"
                :options="programs"
                :aria-describedby="ariaDescribedby"
                :button-variant="
                  program === 'A' ? 'outline-primary' : program === 'V' ? 'outline-success' : 'outline-info'
                "
                size="sm"
                name="radio-btn-outline"
                class="select-radio"
                buttons
              ></b-form-radio-group>
            </b-form-group>
          </b-overlay>
          <template v-if="['V', 'A'].includes(program) ">
            <b class="text-left">{{ $t('form.Score') }}</b>
            <b-overlay :show="show" rounded="sm">
              <b-form-group label="" v-slot="{ ariaDescribedby }" class="card-program" >
                <b-form-radio-group
                  v-model="score"
                  :options="scores"
                  :aria-describedby="ariaDescribedby"
                  :button-variant="score === 'Ye' ? 'outline-warning' : score === 'In' ? 'outline-secondary' : 'light'"
                  size="sm"
                  name="radio-btn-outline"
                  class="select-radio"
                  buttons
                ></b-form-radio-group>
              </b-form-group>
            </b-overlay>
          </template>

          <b class="text-left">{{ $t('form.Language') }}</b>
          <b-overlay :show="show" rounded="sm">
            <b-form-group label="" v-slot="{ ariaDescribedby }" class="card-program">
              <b-form-radio-group
                v-model="language"
                :options="languages"
                :aria-describedby="ariaDescribedby"
                button-variant="outline-danger"
                size="sm"
                name="radio-btn-outline"
                buttons
                class="select-radio"
              ></b-form-radio-group>
            </b-form-group>
          </b-overlay>
        </b-col>
        <b-col class="country-group" cols="3">
          <p class="text-left">
            <b>{{ $t('form.variables') }}</b>
          </p>
          <b-dropdown id="dropdown-text" right text="Variables" class="m-2 mt-4 dropdown-table">
            <b-table striped hover :items="variables"></b-table>
          </b-dropdown>
        </b-col>
      </b-row>
      <b-row v-if="configRol.administrador.pestana.pdf.acciones.editor">
        <b-col class="pl-0"><EditorForm v-model="content" :editContent="editContent" :height="400"/></b-col>
      </b-row>
      <b-row v-else>
        <b-alert show dismissible>
          <p>{{ $t('permissions.msgPermissionsPDF') }}</p>
        </b-alert>
      </b-row>
      <b-row class="mt-3" v-if="program !== 'M' && configRol.administrador.pestana.pdf.acciones.imagenes">
        <b-col>
          <section id="road" class="roadTable">
            <caption>
              <h3>{{ $t('form.Images') }}(Road)</h3>
            </caption>
            <b-form-file
              multiple
              :file-name-formatter="formatNames"
              @change="upload($event)"
              class="files"
            ></b-form-file>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">{{ $t('form.Position') }}</th>
                  <th scope="col">{{ $t('form.FileName') }}</th>
                  <th scope="col">{{ $t('form.View') }}</th>
                  <th scope="col">{{ $t('form.Actions') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(imagen, index) in imagenesRoad" :key="index">
                  <td>
                    <span v-if="formActualizar && idActualizar == index">
                      <b-form-select v-model="indexActualizar" :options="options" size="sm"></b-form-select>
                    </span>
                    <span v-else>
                      {{ imagen.posicion }}
                    </span>
                  </td>
                  <td>
                    {{ imagen.filename }}
                  </td>
                  <td>
                    <div>
                      <b-button v-b-modal="'modal-' + index">{{ $t('form.view') }}</b-button>

                      <b-modal size="lg" :id="'modal-' + index" :title="imagen.filename">
                        <img
                          :src="'data:image/jpeg;base64,' + imagen.base_64_file"
                          alt="Img"
                          :style="{ width: '100%' }"
                        />
                      </b-modal>
                    </div>
                  </td>
                  <td class="column-actions">
                    <!-- Botón para guardar la información actualizada -->
                    <span v-if="formActualizar && idActualizar == index">
                      <button @click="guardarActualizacion(index)" class="btn btn-success-custom">
                        {{ $t('form.Save') }}
                      </button>
                    </span>
                    <span v-else>
                      <!-- Botón para mostrar el formulario de actualizar -->
                      <button @click="verFormActualizar(index)" class="btn btn-blue-dark mr-2">
                        {{ $t('form.Update') }}
                      </button>
                      <!-- Botón para borrar -->
                      <button @click="borrarImagen(index)" class="btn btn-danger-custom">
                        {{ $t('form.Delete') }}
                      </button>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
          </section>
        </b-col>
      </b-row>
      <b-row class="text-center" v-if="configRol.administrador.pestana.pdf.acciones.guardar">
        <b-col class="my-4">
          <b-button
            pill
            class="mx-3 btn-blue-dark"
            @click="saveHTML"
            :disabled="
              !configRol.administrador.pestana.pdf.acciones.editor &&
                !configRol.administrador.pestana.pdf.acciones.imagenes
            "
            >{{ $t('form.Save') }}</b-button
          >
          <!--<b-button pill class="mx-3 editor-button" @click="exportToPDF" disabled>View PDF</b-button>-->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import EditorForm from '@/components/EditorForm';
import Swal from 'sweetalert2';
import { API_UPLOAD_FILE } from '../../Constants/Api';
import { AlertForm, ICON } from '../../helpers/Alert';
import { ApiRequest } from '../../helpers/ApiRequest';
export default {
  name: 'Editor',
  components: { EditorForm },
  data() {
    return {
      content: '',
      show: false,
      editContent: true,
      otro: '',
      edad: 31,
      name: sessionStorage.getItem('nombre') || '',
      lastName: sessionStorage.getItem('apellido') || '',
      country: 'US',
      isActive: false, // default US
      activeProgram: false,
      program: 'V',
      score: 'Ye',
      sendHTML: {},
      imagenesRoad: [],
      // Ver o no ver el formulario de actualizar
      formActualizar: false,
      // La posición de tu lista donde te gustaría actualizar
      idActualizar: -1,
      // Input index dentro del formulario de actualizar
      indexActualizar: '',

      language: 'en',
      languages: [
        { text: 'English', value: 'en' },
        { text: 'Spanish', value: 'es' },
      ],
      configRol: {},
    };
  },
  created() {
    this.configRol = this.$store.state.configRol;
    if (this.configRol.administrador?.pestana.pdf.permiso === false) {
      this.$router.push({ name: 'Welcome' });
      AlertForm(
        `${this.$i18n.t('permissions.unauthorized')} ${this.$i18n.t('admin.pdfTitle')}`,
        this.$i18n.t('permissions.validateAdmin'),
        ICON.WARNING,
        'center',
        null
      );
    }
  },
  computed: {
    programs() {
      return [
        { text: this.translateCol('form.TypeA'), value: 'VA', active: true },
        { text: this.translateCol('form.TypeV'), value: 'V', active: false },
        { text: this.translateCol('form.TypeB'), value: 'A', active: false },
        { text: this.translateCol('medicine.title'), value: 'M', active: false },
      ];
    },
    scores() {
      if(this.country==='US'){
        return [
          { text: this.translateCol('form.Yellow'), value: 'Ye', active: true },
          { text: this.translateCol('form.Intermediate'), value: 'In', active: false },
          { text: this.translateCol('form.White'), value: 'Wh', active: false },
        ];
      }else{
        return [
          { text: this.translateCol('form.Yellow'), value: 'Ye', active: true },
          { text: this.translateCol('form.IntermediateHight'), value: 'In-H', active: false },
          { text: this.translateCol('form.IntermediateLow'), value: 'In-L', active: false },
          { text: this.translateCol('form.White'), value: 'Wh', active: false },
        ];
      }
    },
    variables() {
      return [
        { name: '___nhc___', value: this.translateCol('form.Variables.nhc') },
        {
          name: '___timestamp___',
          value: this.translateCol('form.Variables.timestamp'),
        },
        {
          name: '___patientName___',
          value: this.translateCol('form.Variables.patientName'),
        },
        {
          name: '___programName___',
          value: this.translateCol('form.Variables.programName'),
        },
        {
          name: '___doc_info___',
          value: this.translateCol('form.Variables.docInfo'),
        },
        {
          name: '___med_assistance___',
          value: this.translateCol('form.Variables.medAssistance'),
        },
        {
          name: '___tableComponents___',
          value: this.translateCol('form.Variables.tableComponents'),
        },
        {
          name: '___tableMedicine___',
          value: this.translateCol('form.Variables.tableMedicine'),
        },
        {
          name: '___address.direccion_sucursal_us___',
          value: this.translateCol('form.Variables.addressUS'),
        },
        {
          name: '___address.direccion_sucursal_mx___',
          value: this.translateCol('form.Variables.addressMX'),
        },
        { name: '___sede___', value: this.translateCol('form.Variables.sede') },
        {
          name: '___breakPage___',
          value: this.translateCol('form.Variables.breakPage'),
        },
      ];
    },
  },
  watch: {
    country() {
      this.getInfoPdf();
    },
    program() {
      this.getInfoPdf();
    },
    score() {
      this.getInfoPdf();
    },
    language() {
      this.getInfoPdf();
    },
  },

  mounted() {
    this.getInfoPdf();
  },
  methods: {
    async getInfoPdf() {
      try {
        this.show = true;
        this.editContent = true;
        this.content = '';
        let colorPDF = this.program;
       // if (this.country === 'MX') {
          if (['V', 'A'].includes(this.program)) colorPDF = `${this.program}-${this.score}`;
        //}
       
        let dataImg = await ApiRequest.get(
          `${API_UPLOAD_FILE}obtenerImagenes/color/${colorPDF}/pais/${this.country}/idioma/${this.language}`
        );
        let htmlData = '<p>&nbsp;</p>';
        if (dataImg.data) {
          htmlData = dataImg.data.find((el) => el.tipo_img === 'HTML').base64;
        }
        this.content = htmlData;
        setTimeout(() => {
          this.editContent = false;
        }, 500);
      } catch (error) {
        this.content = '<p>&nbsp;</p>';
      } finally {
        this.show = false;
      }
    },
    countrySelected(country) {
      if (country === 'US') {
        this.isActive = false;
        this.country = 'US';
        this.language = 'en';
      } else {
        this.isActive = true;
        this.country = 'MX';
        this.language = 'es';
      }
    },
    translateCol(colName) {
      return this.$i18n.t(colName);
    },
    conversionData() {
      let colorPDF = this.program;
      //if (this.country === 'MX') {
        if (['V', 'A'].includes(this.program)) colorPDF = `${this.program}-${this.score}`;
      //}

      this.sendHTML = {
        posicion: 1,
        pais: this.country,
        color: colorPDF,
        tipo_img: 'HTML',
        filename: `HTML: ${this.country}-${this.program}`,
        base_64_file: this.content,
        idioma: this.language,
      };
      this.imagenesRoad.forEach((el) => {
        el['tipo_img'] = 'R';
        el['pais'] = this.country;
        el['color'] = colorPDF;
        el['idioma'] = this.language;
      });
    },
    async dataSendAPI() {
      try {
        let info = this.imagenesRoad;
        info.push(this.sendHTML);
        await info.forEach(async (el) => {
          try {
            await ApiRequest.post(`${API_UPLOAD_FILE}salvarImagen`, el);
          } catch (err) {
            AlertForm(`Error Code: ${err.status}`, this.$i18n.t('permissions.errorSaveImg'), ICON.ERROR);
          }
        });
        Swal.fire({
          icon: 'info',
          title: this.translateCol('form.Alert.okTitle'),
          text: this.translateCol('form.Alert.okText'),
          timer: 10000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log('I was closed by the timer');
          }
        });
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.translateCol('form.Alert.error'), ICON.ERROR);
      } finally {
        this.imagenesRoad = [];
      }
    },
    verFormActualizar: function(index) {
      // Antes de mostrar el formulario de actualizar, rellenamos sus campos
      this.idActualizar = index;
      this.indexActualizar = this.imagenesRoad[index].posicion;
      // Mostramos el formulario
      this.formActualizar = true;
    },
    borrarImagen: function(index) {
      // Borramos de la lista
      this.imagenesRoad.splice(index, 1);
      this.imagenesRoad = this.imagenesRoad.map((el, i) => {
        return {
          base_64_file: el.base_64_file,
          filename: el.filename,
          posicion: i + 1 > index ? el.posicion - 1 : el.posicion,
        };
      });
      this.options = this.imagenesRoad.map((el, i) => {
        return { value: i + 1, text: i + 1 };
      });
    },
    guardarActualizacion: function(index) {
      // Ocultamos nuestro formulario de actualizar
      this.formActualizar = false;
      // Actualizamos los datos
      this.imagenesRoad[index].posicion = this.indexActualizar;
    },
    async progressUpload(file, listImagenes, index) {
      if (file.type.slice(0, 5) === 'image') {
        const reader = new FileReader();
        let binary, base_64_file;
        reader.readAsBinaryString(file);
        reader.addEventListener(
          'loadend',
          function() {
            binary = reader.result; // binary data (stored as string), unsafe for most actions
            base_64_file = btoa(binary); // base64 data, safer but takes up more memory
            listImagenes.push({
              posicion: index,
              filename: file.name,
              base_64_file,
            });
          },
          false
        );
      }
    },
    upload(event) {
      const files = Array.from(event.target.files);

      let list = this.imagenesRoad;
      files.forEach((el, index) => this.progressUpload(el, list, list.length + index + 1));
      setTimeout(() => {
        this.imagenesRoad = list;
        this.options = list.map((el, index) => {
          return { value: index + 1, text: index + 1 };
        });
      }, 300);
    },
    formatNames(files) {
      return files.length === 1 ? files[0].name : `${files.length} files selected`;
    },
    saveHTML() {
      Swal.fire({
        title: this.translateCol('form.Alert.saveTitle'),
        text: this.translateCol('form.Alert.saveText'),
        showDenyButton: true,
        confirmButtonText: this.translateCol('form.Alert.save'),
        denyButtonText: this.translateCol('form.Alert.noSave'),
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const index = this.imagenesRoad.map((el) => el.posicion);
          if (new Set(index).size !== index.length) {
            // valida si tiene posiciones repetidas
            return Swal.fire({
              icon: 'warning',
              title: this.translateCol('form.Alert.repeat'),
              text: this.translateCol('form.Alert.repeatText'),
            });
          }

          this.conversionData();
          this.dataSendAPI();
        } else if (result.isDenied) {
          Swal.fire(this.translateCol('form.Alert.cancel'), '', 'info');
        }
      });
    },
    exportToPDF() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style scoped>
@import '../../assets/css/global.css';

/* Estilos de select country*/
.country-group {
  padding: 20px;
  margin-right: 30px;
}
.flex-nowrap {
  flex-wrap: nowrap;
}

/**Inherence global */
.card-country {
  padding: 15px;
  margin: 10px;
  width: 80px;
  height: 80px;
}
/* Estilos botones Programas*/
.card-program {
  padding-top: 5px;
}

.country-group /deep/ .select-radio > label {
  margin-right: 1rem;
  outline: none;
  box-shadow: none;
}
/* Estilos card de variables*/
.card-body:not(.labs) {
  max-height: 485px;
  overflow: scroll;
}
.dropdown-table /deep/ .dropdown-menu {
  max-height: 350px;
  overflow-y: auto;
}

/* imagenes */

.roadTable {
  max-width: 700px;
}

/** Table */

.table td {
  vertical-align: middle;
}

.table .column-actions > span {
  display: flex;
}
</style>
